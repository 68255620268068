import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import Layout from "../components/Layout";
import { parag } from "../styles/global.module.css";
import { contactDiv, link, emailText } from "../styles/about.module.css";

// markup
const AboutPage = () => {
  return (
    <Layout pageTitle="About">
      <p className={parag}>
        I am a generative artist based in São Paulo, Brazil. My background is in
        engineering and I had my first contact with the Processing language in
        2019 when helping my daughter with a science fair project that involved
        Arduino. When I read{" "}
        <a
          href="https://natureofcode.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={link}
        >
          The Nature of Code
        </a>
        , by Daniel Shiffman, I knew that my relationship with the Processing
        world would last. It's the kind of book I've always wanted to study and
        didn't even know it existed.
      </p>
      <p className={parag}>
        Some time later, I got to know the field of generative art through some
        Processing site.
      </p>
      <p className={parag}>
        About the same time I also started to study design, something that had
        interested me for many years and whose concepts have helped me in my art
        projects.
      </p>
      <p className={parag}>Please, find below my contact information:</p>
      <div className={contactDiv}>
        <StaticImage alt="Twitter icon" src="../images/icon_twitter.svg" />
        <a
          href="https://twitter.com/PauloSilvaGArt"
          target="_blank"
          rel="noopener noreferrer"
          className={link}
        >
          @PauloSilvaGArt
        </a>
      </div>
      <div className={contactDiv}>
        <StaticImage alt="Instagram icon" src="../images/icon_instagram.svg" />
        <a
          href="https://www.instagram.com/paulosilvagenart/"
          target="_blank"
          rel="noopener noreferrer"
          className={link}
        >
          @paulosilvagenart
        </a>
      </div>
      <div className={contactDiv}>
        <StaticImage alt="Email icon" src="../images/icon_email.svg" />
        <p className={emailText}>paulosilva.genart (at) gmail.com</p>
      </div>
    </Layout>
  );
};

export default AboutPage;
